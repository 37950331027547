@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400&display=swap');

:root {
    --primary: white;
    --secondary: black;
    --secondary-light: grey;
}

@media (prefers-color-scheme: light) {
    :root {
        --primary: white;
        --secondary: black;
        --secondary-light: grey;
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        --primary: black;
        --secondary: white;
        --secondary-light: grey;
    }
}

html {
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 400;
    background-color: var(--primary);
    color: var(--secondary);
}

body {
    margin: 0;
    -ms-overflow-style: none; /* hide scrollbar IE, Edge */
    scrollbar-width: none; /* hide scrollbar Firefox */
    overflow-y: scroll; 
}

body::-webkit-scrollbar {
    display: none; /* hide scrollbar Chrome, Safari, and Opera */
}

#app {
    margin: 0 15vw 45vh 15vw;
}

header {
    margin: 45vh 0;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1; 
    background-color: var(--primary);
}

#name {
    cursor: pointer;
    display: flex;
    align-items:center;
    font-size: 64px;
    font-weight:800;
    min-height: 10.1vh;
    margin: 0;
}

#navbar {
    display: flex;
    flex-direction: row;
    align-items:center;
}

#nav_button {
    margin: 0;
    padding: 0;
    padding-left: 16px;
    cursor: pointer;
    background-color: transparent;
    color: var(--secondary-light);
    font-family: 'IBM Plex Mono', monospace;
    font-size: 16px;
    border-width: 0;
}

.active {
    color: var(--secondary);
}

.section {
    margin: 0 0 64px 0;
}

.section_heading {
    margin: 0;
    color: var(--secondary);
    font-size: 32px;
}

.subsection {
    margin: 32px 0 32px 32px;
}

.item_name {
    margin: 0;
    padding: 0;
    color: var(--secondary);
    font-size: 24px;
}

.item_description {
    margin: 0;
    color: var(--secondary-light);
    font-size: 16px;
}

.project {
    margin: 0 0 32px 0;
    display: flex;
    flex-direction: column;
}

.gallery_item > div {
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    align-items: center;
}

.gallery_item > div > div{
    margin-left: 32px;
}

.media {
    max-width: 160px;
}

.social {
    margin: 0 0 32px 0;
    display: flex;
}

.social > svg {
    margin-right: 16px;
    color: var(--secondary-light);
}